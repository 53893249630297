import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const landingPage = createApi({
  reducerPath: "landingPage",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    fetchFn: unAuthenticatedUser
  }),
  tagTypes: ["landing"],
  endpoints: (builder) => ({
    getlandingPage: builder.query({
      query: (professionName) => `/landing-pages/${professionName}`,
      providesTags: [{ type: "landing", id: "landing-page-data" }],
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetlandingPageQuery } = landingPage;
