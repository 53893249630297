import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const impersonate = createApi({
  reducerPath: "impersonate",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Impersonate"],
  endpoints: (builder) => ({
    impersonateProcessStart: builder.mutation({
      query: (id) => {
        return {
          url: `/api/admin/v2/impersonate/user/${id}`,
          method: "POST",
        };
      },
      invalidatesTags: [{ type: "Impersonate", id: "impersonateStart" }],
    }),
    impersonateProcessStop: builder.mutation({
      query: () => {
        return {
          url: "/api/v2/impersonate/stop",
          method: "POST",
        };
      },
      invalidatesTags: [{ type: "Impersonate", id: "impersonateStop" }],
    }),
  }),
});

export const {
  useImpersonateProcessStartMutation,
  useImpersonateProcessStopMutation,
} = impersonate;
