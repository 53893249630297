import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getInvoicesDetails = createApi({
  reducerPath: "invoicesDetails",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getInvoicesDetails: builder.query({
      query: () => `/invoices`,
    }),
    downloadInvoice: builder.query({
      query: (invoiceId) => `/invoice/${invoiceId}`,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetInvoicesDetailsQuery, useDownloadInvoiceQuery } =
  getInvoicesDetails;
