import {createSlice} from '@reduxjs/toolkit'

const globalLoaderSlice = createSlice({
    name:"globalLoaderSlice",
    initialState:{
        isLoading:false
    },
    reducers:{
        setLoader:(state,action)=>{
            state.isLoading=action.payload
        }
    }

})

export const {setLoader} = globalLoaderSlice.actions

export default globalLoaderSlice.reducer