import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const getLeadQuestions = createApi({
  reducerPath: "leadQuestionsInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getQuestionnaires: builder.query({
      query: (profession_id) =>
        `/professions/${profession_id}/questionnaires`,
    }),
    getQuestions: builder.query({
      query: (questionnaire_id) =>
        `/questionnaires/${questionnaire_id}/questions`,
    }),
    leadSituationNote: builder.mutation({
      query: (match) => ({
        url: `/matches/${match.id}`,
        method: "POST",
        body: match,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetQuestionnairesQuery,
  useGetQuestionsQuery,
  useLeadSituationNoteMutation,
} = getLeadQuestions;
