import React from "react";
import LandingPageNavbar from "../components/landingPageNavbar";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import ErrorImage from "../images/404.svg"

const ErrorPage = () => {
  const navigate = useNavigate()
  const handleBackButton =()=> {
    navigate("/")
  }
  return (
    <>
      <LandingPageNavbar onBoard={true} />
      <div className="pt-[150px] md:pt-[100px] pb-[50px]">
        <div className="container">
          <div className="flex flex-col items-center justify-center">
            <img src={ErrorImage} alt="" className="" />
            <h1 className="text-[80px] md:text-[150px] text-primary font-black leading-[120%]">404</h1>
            <p className="text-lg md:text-xl font-bold leading-[160%] text-customGray mb-6">Page Not Found</p>
            <Button classes="bg-primary text-white h-12 px-10 rounded-[10px] text-base font-bold leading-[160%]" title="Back To Home" onClick={handleBackButton} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
