import {
  Conversation,
  Media,
  Message,
  Participant,
  User,
} from "@twilio/conversations";

export const conversationsMap = new Map();
export const messagesMap = new Map();
export const usersMap = new Map();
export const mediaMap = new Map();
export const participantsMap = new Map();

const capitalize = (string) =>
  `${string[0].toUpperCase()}${string.substring(1)}`;

  const getSdkObject = (objectMap, sid, type) => {
    if (
      !["conversation", "message", "media", "participant", "user"].includes(type)
    ) {
      throw new Error(
        `Invalid type: ${type}. Expected one of "conversation", "message", "media", "participant", or "user".`
      );
    }
    if (objectMap?.sid === sid) {
        return objectMap;
    }

    // If the SID doesn't match or the objectMap is null/undefined
    if (!objectMap || !objectMap?.sid) {
      throw new Error(`${type.charAt(0).toUpperCase() + type.slice(1)} with SID ${sid} was not found.`);
    }

    return {};
};

export const getSdkConversationObject = (reduxConversation) => {
  return getSdkObject(reduxConversation, reduxConversation?.sid, "conversation");
};

export const getSdkMessageObject = (reduxMessage) =>
  getSdkObject(messagesMap, reduxMessage?.sid, "message");

export const getSdkUserObject = (reduxUser) =>
  getSdkObject(usersMap, reduxUser.identity, "user");

export const getSdkMediaObject = (reduxMedia) =>
  getSdkObject(mediaMap, reduxMedia?.sid, "media");

export const getSdkParticipantObject = (reduxParticipant) =>
  getSdkObject(participantsMap, reduxParticipant?.sid, "participant");
