import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getProfessionalMatches = createApi({
  reducerPath: "professionalMatches",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: ["matchesType"],
  endpoints: (builder) => ({
    getProfessionalMatches: builder.query({
      query: (arg) => getQueryUrl("/matches/?", arg),
      providesTags: [{ type: "matchesType", id: "get-matches" }],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetProfessionalMatchesQuery,
  useLazyGetProfessionalMatchesQuery,
} = getProfessionalMatches;

const getQueryUrl = (baseUrl, params) => {
  let query = baseUrl;

  if (params?.page) {
    query += `page=${params.page}&`;
  }
  if (params?.perPage) {
    query += `perPage=${params.perPage}&`;
  }

  if (params?.start_date && params.start_date != "Invalid date") {
    query += `start_date=${params.start_date}&`;
  }

  if (params?.end_date && params.end_date != "Invalid date") {
    query += `end_date=${params.end_date}&`;
  }
  if (params?.date && params.date != "Invalid date") {
    query += `date=${params.date}&`;
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};
