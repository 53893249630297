import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const creditRequests = createApi({
  reducerPath: "creditRequests",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/admin/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin,
  }),
  tagTypes: ["Credit_Requests"],
  endpoints: (builder) => ({
    getAllCreditRequests: builder.query({
      query: (body) => getQueryUrl(body),
      providesTags: [{ type: "Credit_Requests", id: "getAllCreditRequests" }],
    }),
    approveCreditRequest: builder.mutation({
      query: ({ selectedCreditRequestId, ...info }) => {
        return {
          url: `/credit-requests/${selectedCreditRequestId}/approve`,
          method: "POST",
          body: info,
        };
      },
      invalidatesTags: [
        { type: "Credit_Requests", id: "getAllCreditRequests" },
      ],
    }),
    denyCreditRequest: builder.mutation({
      query: ({ selectedCreditRequestId, ...info }) => {
        return {
          url: `/credit-requests/${selectedCreditRequestId}/deny`,
          method: "POST",
          body: info,
        };
      },
      invalidatesTags: [
        { type: "Credit_Requests", id: "getAllCreditRequests" },
      ],
    }),
    getSortedCreditRequests: builder.query({
      query: (body) => getQueryUrl(body),
    }),
    getAllFilteredCreditRequests: builder.query({
      query: (body) => `/credit-requests/?page=${body.page}`,
    }),
  }),
});

export const {
  useGetAllCreditRequestsQuery,
  useLazyGetAllCreditRequestsQuery,
  useApproveCreditRequestMutation,
  useDenyCreditRequestMutation,
  useGetSortedCreditRequestsQuery,
  useLazyGetSortedCreditRequestsQuery,
  useLazyGetAllFilteredCreditRequestsQuery,
} = creditRequests;

const getQueryUrl = (params) => {
  let query = "/credit-requests/?";

  if (params.sort) {
    query += `sort=${params.sort}&`;
  }
  if (params.statuses) {
    query += `statuses=${params.statuses}&`;
  }
  if (params.perPage) {
    query += `per_page=${params.perPage}&`;
  }
  if (params.page) {
    query += `page=${params.page}&`;
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};
