import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const authTwilioChatToken = createApi({
  reducerPath: "authtwiliochat",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAuthTwilioChatToken: builder.mutation({
      query: () => ({
        url: `/chat/auth/generate-token`,
        method: "POST",
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetAuthTwilioChatTokenMutation } = authTwilioChatToken;

export const twilioChatToken = createApi({
  reducerPath: "twiliochat",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    prepareHeaders: (headers, { getState }) => {
      headers.set("Accept", "application/json");
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getTwilioChatToken: builder.mutation({
      query: (matchId) => ({
        url: `/chat/generate-token`,
        method: "POST",
        body: matchId,
      }),
    }),
  }),
});

export const { useGetTwilioChatTokenMutation } = twilioChatToken;
