import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Message } from "@twilio/conversations";
import {
  mediaMap,
  messagesMap,
  usersMap,
} from "../../components/dashboard/twilio/conversations-objects";

// Initial state for the chat slice
const initialState = {
  sid: "",
  token: null,
  conversations: [],
  currentConversation: null,
  lastReadIndex: null,
  messages: {},
  loadingState: false,
  participants: {},
  user: null,
  unreadMessages: {},
  attachments: {},
  typing: {},
  notifications: [],
  filteredConversations: [],
  timeFormat: false,
  local: null,
};

// Thunks for handling the logic
export const updateTimeFormatThunk = createAsyncThunk(
  "chat/updateTimeFormat",
  async (on, { dispatch }) => {
    if (on) {
      localStorage.setItem("use24hTimeFormat", "true");
    } else {
      localStorage.removeItem("use24hTimeFormat");
    }
    dispatch(chatSlice.actions.updateTimeFormat(on));
  }
);

export const updateLocalThunk = createAsyncThunk(
  "chat/updateLocal",
  async (local, { dispatch }) => {
    localStorage.setItem("local", local);
    dispatch(chatSlice.actions.updateLocal(local));
  }
);

// Helper function to convert Message to ReduxMessage
const reduxifyMessage = (message) => ({
  sid: message.sid,
  index: message.index,
  body: message.body,
  author: message.author,
  participantSid: message.participantSid,
  attributes: message.attributes,
  dateCreated: message.dateCreated ? message.dateCreated.toISOString() : null,
  aggregatedDeliveryReceipt: message.aggregatedDeliveryReceipt
    ? {
        total: message.aggregatedDeliveryReceipt.total,
        sent: message.aggregatedDeliveryReceipt.sent,
        delivered: message.aggregatedDeliveryReceipt.delivered,
        read: message.aggregatedDeliveryReceipt.read,
        undelivered: message.aggregatedDeliveryReceipt.undelivered,
        failed: message.aggregatedDeliveryReceipt.failed,
      }
    : null,
  attachedMedia:
    message.attachedMedia?.map((el) => ({
      sid: el.sid,
      filename: el.filename,
      contentType: el.contentType,
      size: el.size,
      category: el.category,
    })) ?? null,
});

const reduxifyUser = (user) => ({
  identity: user.identity,
  friendlyName: user.friendlyName ?? "",
});

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.token = null;
    },
    upsertConversation: (state, action) => {
      const {
        sid,
        friendlyName,
        dateUpdated,
        notificationLevel,
        lastReadMessageIndex,
        lastMessage,
      } = action.payload;

      const filteredClone = state.conversations.filter(
        (conversation) => conversation.sid !== sid
      );

      state.conversations = [
        ...filteredClone,
        {
          sid,
          friendlyName,
          dateUpdated,
          notificationLevel,
          lastReadMessageIndex,
          lastMessage: {
            ...lastMessage,
          },
        },
      ].sort(
        (a, b) =>
          (b.lastMessage?.dateCreated?.getTime() ??
            b.dateUpdated?.getTime() ??
            0) -
          (a.lastMessage?.dateCreated?.getTime() ??
            a.dateUpdated?.getTime() ??
            0)
      );
    },
    removeConversation: (state, action) => {
      state.conversations = state.conversations.filter(
        (convo) => convo.sid !== action.payload
      );
    },
    updateCurrentConversation: (state, action) => {
      state.currentConversation = action.payload;
    },
    setLastReadIndex: (state, action) => {
      state.lastReadIndex = action.payload;
    },
    upsertMessages: (state, action) => {
      const { channelSid, messages: messagesToAdd } = action.payload;
      const existingMessages = state.messages[channelSid] || [];

      const filteredExistingMessages = existingMessages.filter(
        (message) =>
          !messagesToAdd.find(
            (value) =>
              value.body === message.body &&
              value.author === message.author &&
              (message.index === -1 || value.index === message.index)
          )
      );

      const messagesUnique = [
        ...filteredExistingMessages,
        ...messagesToAdd.map(reduxifyMessage),
      ];

      messagesToAdd.forEach((message) => {
        if (message instanceof Message) {
          messagesMap.set(message.sid, message);
          if (message.attachedMedia) {
            message.attachedMedia.forEach((media) => {
              mediaMap.set(media.sid, media);
            });
          }
        }
      });

      state.messages[channelSid] = messagesUnique.sort(
        (a, b) => a.index - b.index
      );
    },
    pushMessages: (state, action) => {
      const { channelSid, messages: messagesToAdd } = action.payload;
      const existingMessages = state.messages[channelSid] || [];

      messagesToAdd.forEach((message) => {
        messagesMap.set(message.sid, message);
        if (message.attachedMedia) {
          message.attachedMedia.forEach((media) => {
            mediaMap.set(media.sid, media);
          });
        }
      });

      state.messages[channelSid] = [
        ...existingMessages,
        ...messagesToAdd.map(reduxifyMessage),
      ];
    },
    removeMessages: (state, action) => {
      const { channelSid, messages: messagesToRemove } = action.payload;
      const existingMessages = state.messages[channelSid] || [];

      state.messages[channelSid] = existingMessages.filter(
        ({ index }) =>
          !messagesToRemove.find(
            ({ index: messageIndex }) => messageIndex === index
          )
      );

      messagesToRemove.forEach((message) => {
        messagesMap.delete(message.sid);
        if (message.attachedMedia) {
          message.attachedMedia.forEach((media) => {
            mediaMap.delete(media.sid);
          });
        }
      });
    },
    updateLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },
    updateParticipants: (state, action) => {
      const { participants, sid } = action.payload;
      state.participants[sid] = participants?.map((participant) => ({
        sid: participant.sid,
        attributes: participant.attributes,
        identity: participant.identity,
        type: participant.type,
        lastReadMessageIndex: participant.lastReadMessageIndex,
      }));
    },
    updateUser: (state, action) => {
      const user = action.payload;
      usersMap.set(user.identity, user);

      state[user.identity] = reduxifyUser(user);
    },
    updateUnreadMessages: (state, action) => {
      const { channelSid, unreadCount } = action.payload;
      state.unreadMessages[channelSid] = unreadCount;
    },
    updateConversation: (state, action) => {
      const target = state.conversations.find(
        (convo) => convo.sid === action.payload.channelSid
      );

      if (target) {
        Object.assign(target, {
          ...action.payload.parameters,
        });
      }
    },
    addAttachment: (state, action) => {
      const { channelSid, messageSid, mediaSid, attachment } = action.payload;
      state.attachments[channelSid] = state.attachments[channelSid] ?? {};
      state.attachments[channelSid][messageSid] =
        state.attachments[channelSid][messageSid] ?? {};
      state.attachments[channelSid][messageSid][mediaSid] = attachment;
    },
    clearAttachments: (state, action) => {
      const { channelSid, messageSid } = action.payload;
      if (state.attachments[channelSid]) {
        delete state.attachments[channelSid][messageSid];
      }
    },
    startTyping: (state, action) => {
      const { channelSid, participant } = action.payload;
      const existedUsers = state.typing[channelSid] || [];
      const uniqueUsers = Array.from(new Set([...existedUsers, participant]));
      state.typing[channelSid] = uniqueUsers;
    },
    endTyping: (state, action) => {
      const { channelSid, participant } = action.payload;
      state.typing[channelSid] = (state.typing[channelSid] || []).filter(
        (user) => user !== participant
      );
    },
    addNotifications: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    setFilteredConversations: (state, action) => {
      state.filteredConversations = action.payload;
    },
    updateTimeFormat: (state, action) => {
      state.timeFormat = action.payload;
    },
    updateLocal: (state, action) => {
      state.local = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTimeFormatThunk.fulfilled, (state, action) => {
        state.timeFormat = action.payload;
      })
      .addCase(updateLocalThunk.fulfilled, (state, action) => {
        state.local = action.payload;
      });
  },
});

export default chatSlice.reducer;

export const {
  login,
  logout,
  upsertConversation,
  removeConversation,
  updateCurrentConversation,
  setLastReadIndex,
  upsertMessages,
  pushMessages,
  removeMessages,
  updateLoadingState,
  updateParticipants,
  updateUser,
  updateUnreadMessages,
  updateConversation,
  addAttachment,
  clearAttachments,
  startTyping,
  endTyping,
  addNotifications,
  clearNotifications,
  setFilteredConversations,
  updateTimeFormat,
  updateLocal,
} = chatSlice.actions;
