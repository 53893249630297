import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const paymentCreation = createApi({
  reducerPath: "paymentcreation",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    paymentCreation: builder.mutation({
      query: (body) => ({
        url: `/credits/pay`,
        method: "POST",
        body,
      }),
    }),
    getPaymentMehods: builder.query({
      query: () => `/payment-methods`,
    }),
    savePaymentMethods: builder.mutation({
      query: (info) => ({
        url: `/payment-methods`,
        method: "POST",
        body: info,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  usePaymentCreationMutation,
  useGetPaymentMehodsQuery,
  useLazyGetPaymentMehodsQuery,
  useSavePaymentMethodsMutation,
} = paymentCreation;
