import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Button from "../button";
import { animateScroll as scroll } from "react-scroll";

const LandingPageNavbar = ({ onBoard }) => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".landing-page-header");

      if (header) {
        const scrollPosition = window.scrollY;

        if (scrollPosition > 100) {
          header.classList.add("bg-color-change");
        } else {
          header.classList.remove("bg-color-change");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const banner = document.getElementById("landingpagebanner"); // Replace 'banner' with your banner's id
      const bannerBottom = banner?.getBoundingClientRect().bottom;
      const scrolled = window.scrollY;

      // Show the button if scrolled below a certain distance from the banner's bottom
      if (scrolled > bannerBottom + 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    const options = {
      duration: 0,
      smooth: true,
    };
    scroll.scrollToTop(options);
    setShowButton(false);
  };

  return onBoard ? (
    <div className="py-4 md:py-[25px] absolute w-full top-0 left-0 border-b border-[#CCD0D4]">
      <div className="container flex justify-center">
        <Link to="/" className="flex-shrink-0 inline-block">
          <img
            className="w-auto h-[34px] sm:h-11"
            src={Logo}
            alt="Your Company"
          />
        </Link>
      </div>
    </div>
  ) : (
    <header className="landing-page-header py-6 fixed w-full top-0 left-0 z-10 transition-all">
      <div className="container">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex-shrink-0 lg:mr-32 inline-block">
            <img className="w-auto h-7 sm:h-11" src={Logo} alt="Your Company" />
          </Link>
          {showButton && (
            <Button
              title="get matched now"
              classes="px-4 md:px-8 py-3 md:py-4 bg-primary text-sm md:text-base text-white rounded-xl font-black duration-500 hover:bg-[#026ed6] capitalize"
              onClick={scrollToTop}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default LandingPageNavbar;
