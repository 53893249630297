import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const getInsuranceDetails = createApi({
  reducerPath: "insuranceDetails",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    fetchFn: unAuthenticatedUser
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getInsuranceDetails: builder.query({
      query: () => `/insurance-carriers`,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetInsuranceDetailsQuery } = getInsuranceDetails;