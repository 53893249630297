import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const resetPassword = createApi({
  reducerPath: "resetPassword",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    sendEmailDetails: builder.mutation({
      query: (email) => ({
        url: "/forgot-password",
        method: "POST",
        body: email,
      }),
    }),
    saveResetPasswordDetails: builder.mutation({
      query: (password) => ({
        url: "/reset-password",
        method: "POST",
        body: password,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useSendEmailDetailsMutation,
  useSaveResetPasswordDetailsMutation,
} = resetPassword;
