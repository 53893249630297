import {createSlice} from '@reduxjs/toolkit'

const coursesSlice = createSlice({
    name:"coursesSlice",
    initialState:{
        coursesList:[],
        completedLessons:[]
    },
    reducers:{
        setCourses:(state,action)=>{
            state.coursesList=action.payload
        },
        setCompletedLessons:(state,action)=>{
            state.completedLessons=action.payload
        }
    }

})

export const {setCourses,setCompletedLessons} = coursesSlice.actions

export default coursesSlice.reducer