import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const professionalAnnouncement = createApi({
  reducerPath: "professionalAnnouncement",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser
  }),
  tagTypes: ['professional-announcement'],
  endpoints: (builder) => ({
    getProfessionalAnnouncement: builder.query({
      query: () => `/announcements`,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetProfessionalAnnouncementQuery } = professionalAnnouncement;
