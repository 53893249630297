import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getCreditRefundDetails = createApi({
  reducerPath: "creditRefundDetails",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getCreditsRefundDetails: builder.query({
      query: () => `/credit-requests`,
    }),
    saveCreditsRefund: builder.mutation({
      query: (body) => ({
        url: `/credit-requests`,
        method: "POST",
        body,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetCreditsRefundDetailsQuery, useSaveCreditsRefundMutation } =
  getCreditRefundDetails;
