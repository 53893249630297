import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";

const PrivateRoute = ({ admin }) => {
  const { login, isAuthenticated } = useAuth();
  const isAuth = isAuthenticated || localStorage.getItem("token");
  if (!isAuthenticated && isAuth) {
    login();
  }

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={admin ? "/admin-signin" : "/signin"} replace />
  );
};

export default PrivateRoute;
