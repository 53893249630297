import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  professionals: [],
  isLoading: false,
  success: false,
  type: ''
};

export const professionalsSlice = createSlice({
  name: "professionalsSlice",
  initialState,
  reducers: {
    saveProfessionalsSignUpRequest(state) {
      return { ...state, isLoading: true, type: 'saveProfessionalsSignUpRequest' };
    },
    saveProfessionalsSignUpSuccess(state) {
      return { ...state, isLoading: false, success: true, type: 'saveProfessionalsSignUpSuccess' };
    },
    saveProfessionalsSignUpFailure(state) {
      return { ...state, success: false, isLoading: false, type: 'saveProfessionalsSignUpFailure' };
    },
  },
});

// Action creators are generated for each case reducer  function
export const {
  saveProfessionalsSignUpRequest,
  saveProfessionalsSignUpSuccess,
  saveProfessionalsSignUpFailure,
} = professionalsSlice.actions;
export default professionalsSlice.reducer;
