import { createSlice } from "@reduxjs/toolkit";

// Create slice for professionalInfo
const creditsRefundSlice = createSlice({
  name: "creditsRefundSlice",
  initialState: {
    credits: []
  },
  reducers: {
    setCreditsRefundRequest: (state, action) => {
      state.credits = action.payload;
    },
  },
});

// Export actions from the slice
export const { setCreditsRefundRequest } = creditsRefundSlice.actions;

// Export the reducer
export default creditsRefundSlice.reducer;
