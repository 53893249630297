import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const announcements = createApi({
  reducerPath: "announcements",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/admin/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin,
  }),
  tagTypes: ["Announcements"],
  endpoints: (builder) => ({
    getAllAnnouncements: builder.query({
      query: () => "/announcements",
      providesTags: [{ type: "Announcements", id: "get_announcement" }],
    }),
    createAnnouncement: builder.mutation({
      query: (body) => ({ url: `/announcements`, method: "POST", body }),
      invalidatesTags: [{ type: "Announcements", id: "get_announcement" }],
    }),
    updateAnnouncement: builder.mutation({
      query: ({id, ...body}) => ({
        url: `/announcements/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Announcements", id: "get_announcement" }],
    }),
    deleteAnnouncement: builder.mutation({
      query: (id) => ({ url: `/announcements/${id}`, method: "DELETE" }),
      invalidatesTags: [{ type: "Announcements", id: "get_announcement" }],
    }),
  }),
});

export const {
  useGetAllAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = announcements;
