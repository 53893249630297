import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const leads = createApi({
  reducerPath: "leads",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/admin/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin,
  }),
  tagTypes: ["Leads"],
  endpoints: (builder) => ({
    getAllLeads: builder.query({ query: (page = 1) => `/leads/?page=${page}` }),
    getFilteredLeads: builder.query({
      query: (body) => getQueryUrl(body),
    }),
  }),
});

export const {
  useGetAllLeadsQuery,
  useLazyGetAllLeadsQuery,
  useGetFilteredLeadsQuery,
  useLazyGetFilteredLeadsQuery,
} = leads;

const getQueryUrl = (params) => {
  let query = "/leads/?";

  if (params.page) {
    query += `page=${params.page}&`;
  }

  if (params.startDate && params.startDate != "Invalid date") {
    query += `start_date=${params.startDate}&`;
  }

  if (params.endDate && params.endDate != "Invalid date") {
    query += `end_date=${params.endDate}&`;
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};
