import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const matchesInfo = createApi({
  reducerPath: "matchesInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/admin/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin,
  }),
  tagTypes: ["MatchesInfoType"],
  endpoints: (builder) => ({
    getAllMatchesInfo: builder.query({
      query: (page = 1) => `/matches/?page=${page}`,
      providesTags: [{ id: "MatchesInfoType", type: "allMatchedInfo" }],
    }),
    getMatchDetails: builder.query({ query: (id) => `/matches/${id}` }),
    getAllUnmatchedInfo: builder.query({
      query: (page = 1) => `/unmatched/?page=${page}`,
      providesTags: [{ id: "MatchesInfoType", type: "allUnmatchedInfo" }],
    }),
    getUnMatchDetails: builder.query({ query: (id) => `/leads/${id}` }),
    getAllPendingMatchesInfo: builder.query({
      query: (page = 1) => `/pending/?page=${page}`,
    }),
    getAllFilteredMatches: builder.query({
      query: (body) => getQueryUrl("/matches/?", body),
    }),
    getAllFilteredUnMatches: builder.query({
      query: (body) => getQueryUrl("/unmatched/?", body),
    }),
    getAllFilteredPendingMatch: builder.query({
      query: (body) => getQueryUrl("/pending/?", body),
    }),
    getPendingMatchDetails: builder.query({ query: (id) => `pending/${id}` }),
    deleteLead: builder.mutation({
      query: (leadId) => ({
        url: `/leads/${leadId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ id: "MatchesInfoType", type: "allUnmatchedInfo" }],
    }),
    deleteMatchLead: builder.mutation({
      query: (matchId) => ({
        url: `/match/${matchId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ id: "MatchesInfoType", type: "allMatchedInfo" }],
    }),
  }),
});

export const {
  useGetAllMatchesInfoQuery,
  useGetMatchDetailsQuery,
  useGetAllUnmatchedInfoQuery,
  useGetUnMatchDetailsQuery,
  useLazyGetAllMatchesInfoQuery,
  useLazyGetAllUnmatchedInfoQuery,
  useGetAllFilteredMatchesQuery,
  useLazyGetAllFilteredMatchesQuery,
  useLazyGetAllFilteredUnMatchesQuery,
  useGetAllPendingMatchesInfoQuery,
  useLazyGetAllPendingMatchesInfoQuery,
  useLazyGetAllFilteredPendingMatchQuery,
  useGetPendingMatchDetailsQuery,
  useDeleteLeadMutation,
  useDeleteMatchLeadMutation,
} = matchesInfo;

const getQueryUrl = (baseUrl, params) => {
  let query = baseUrl;

  if (params.page) {
    query += `page=${params.page}&`;
  }
  if (params.perPage) {
    query += `perPage=${params.perPage}&`;
  }

  if (params.startDate && params.startDate != "Invalid date") {
    query += `start_date=${params.startDate}&`;
  }

  if (params.endDate && params.endDate != "Invalid date") {
    query += `end_date=${params.endDate}&`;
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};
