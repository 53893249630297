import { createSlice } from "@reduxjs/toolkit";

// Create slice for professionalInfo
const matchesSlice = createSlice({
  name: "matchesSlice",
  initialState: {
    matches: []
  },
  reducers: {
    setMatches: (state, action) => {
      state.matches = action.payload;
    },
  },
});

// Export actions from the slice
export const { setMatches } = matchesSlice.actions;

// Export the reducer
export default matchesSlice.reducer;
