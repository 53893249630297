import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const getPaymentDetails = createApi({
  reducerPath: "paymentdetails",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getPaymentDetails: builder.query({
      query: () => `/payment-options`,
      transformResponse: (response) => {
        if (Array.isArray(response)) {
          // Filter out items without price and then sort
          const itemsWithPrice = response.filter(
            (item) => item && typeof item.price === "number"
          );

          if (itemsWithPrice.length > 0) {
            // Sorting if there are items with valid prices
            return itemsWithPrice.sort((a, b) => a.price - b.price);
          }
        }
        return response;
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetPaymentDetailsQuery } = getPaymentDetails;
