import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState: {
    notificationsList: [],
  },
  reducers: {
    setNotificationsList: (state, action) => {
      state.notificationsList = action.payload;
    },
  },
});

export const { setNotificationsList } = notificationsSlice.actions;

export default notificationsSlice.reducer;
