import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const adminMetrics = createApi({
  reducerPath: "adminMetrics",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/admin/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin,
  }),
  tagTypes: ["Admin-Metrics"],
  endpoints: (builder) => ({
    getAdminMetrics: builder.query({
      query: (body) => getQueryUrl(body),
    }),
  }),
});

export const { useGetAdminMetricsQuery, useLazyGetAdminMetricsQuery } =
  adminMetrics;

const getQueryUrl = (params) => {
  let query = "/metrics/dashboard/?";

  if (params.startDate) {
    query += `start_date=${params.startDate}&`;
  }

  if (params.endDate) {
    query += `end_date=${params.endDate}&`;
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};
