import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 20,
}

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    setProfileCompletePercentage: (state, {payload}) => {
      state.value += payload
    },
  },
})

// Action creators are generated for each case reducer  function
export const { setProfileCompletePercentage } = profileSlice.actions
export default profileSlice.reducer