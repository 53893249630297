import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getCourses = createApi({
  reducerPath: "courses",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: ["Courses"],
  endpoints: (builder) => ({
    getCourses: builder.query({
      query: () => `/courses`,
      providesTags: [{ type: "Courses", id: "getCourses" }],
    }),
    getCompletedLessons: builder.query({
      query: () => `/lessons`,
    }),
    completedLesson: builder.mutation({
      query: (body) => ({ url: `/lessons`, method: "POST", body }),
      invalidatesTags: [{ type: "Courses", id: "getCourses" }],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetCoursesQuery,
  useLazyGetCoursesQuery,
  useGetCompletedLessonsQuery,
  useCompletedLessonMutation,
  useLazyGetCompletedLessonsQuery,
} = getCourses;
