import React from "react";
import '../../Loader.css'

const Loader = ({isLoading}) => {

  return (
    <>
      {isLoading && (
       <div className="loader-wrapper w-full h-full flex items-center justify-center">
         <div className="loader"></div>
       </div>
      )}
    </>
  );
};

export default Loader;
