import { useEffect, lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";
import Loader from "./components/commons/Loader";
import { GlobalContextProvider } from "./contextApi/GlobalContextProvider";
import PrivateRoute from "./routes/PrivateRoute";
import AdminRoutes from "./routes/AdminRoutes";
import ErrorPage from "./pages/ErrorPage";
import FacebookPixel, { trackCustomEvent } from "./FacebookPixel";
import Hotjar from "@hotjar/browser";

const trackingId = process.env.REACT_APP_GA4_TRACKING_ID;
const siteId = process.env.REACT_APP_HOTJAR_SITE_ID;
const hotjarVersion = 6;

// Lazy load your components
const HomePage = lazy(() => import("./pages/HomePage"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const OnBoardingPage = lazy(() => import("./pages/OnBoardingPage"));
const DashboardLayout = lazy(() =>
  import("./components/dashboard/dashboardLayout")
);
const SignUpFlowPage = lazy(() => import("./pages/SignUpFlowPage"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"));
const UseTermsPage = lazy(() => import("./pages/UseTermsPage"));
const ServicesTermsPage = lazy(() => import("./pages/ServicesTermsPage"));
const ProfileOptimizationPage = lazy(() =>
  import("./pages/ProfileOptimizationPage")
);
const DashboardMain = lazy(() =>
  import("./components/dashboard/dashboardMain")
);
const ProfessionalDashboardMessages = lazy(() =>
  import(
    "./components/dashboard/dashboardMessages/ProfessionalDashboardMatches"
  )
);
const ProfessionalDashboardOldMessages = lazy(() =>
  import(
    "./components/dashboard/dashboardMessages/ProfessionalDashboardOldMatches"
  )
);
const DashboardMatchesMain = lazy(() =>
  import("./components/dashboard/dashboardMatches/DashboardMatchesMain")
);
const LeadDetails = lazy(() =>
  import("./components/dashboard/dashboardMatches/LeadDetails")
);
const LeadDetailsSample = lazy(() =>
  import("./components/dashboard/dashboardMatches/LeadDetailsSample")
);
const DashboardInvoice = lazy(() =>
  import("./components/dashboard/dashboardInvoice")
);
const DashboardEducationCenter = lazy(() =>
  import(
    "./components/dashboard/dashboardEducationCenter/DashboardEducationCenter"
  )
);
const CoursesDetail = lazy(() =>
  import("./components/dashboard/dashboardEducationCenter/CoursesDetail")
);
const LessonDetail = lazy(() =>
  import("./components/dashboard/dashboardEducationCenter/LessonDetail")
);
const SettingsLayout = lazy(() =>
  import("./components/dashboard/dashboardSettings/SettingsLayout")
);
const UserProfile = lazy(() =>
  import("./components/dashboard/dashboardSettings/UserProfile")
);
const CalendarIntegration = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/CalendarIntegration"
  )
);
const CreditLimit = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/CreditLimit"
  )
);
const Region = lazy(() =>
  import("./components/dashboard/dashboardSettings/userProfileDetail/Region")
);
const SmsTemplate = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/SmsTemplate"
  )
);
const EmailTemplate = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/EmailTemplate"
  )
);
const BillingPayment = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/BillingPayment"
  )
);
const NotificationSettings = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/NotificationSettings"
  )
);
const EmailTemplateEditor = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/EmailTemplateEditor"
  )
);
const LastPage = lazy(() => import("./pages/LastPage"));
const PasswordSettings = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/PasswordSettings"
  )
);
const SecuritySettings = lazy(() =>
  import(
    "./components/dashboard/dashboardSettings/userProfileDetail/SecuritySettings"
  )
);
const DashboardSupportMain = lazy(() =>
  import("./components/dashboard/dashboardSupport/DashboardSupportMain")
);
const SupportLayout = lazy(() =>
  import("./components/dashboard/dashboardSupport/SupportLayout")
);
const SupportContactUs = lazy(() =>
  import("./components/dashboard/dashboardSupport/SupportContactUs")
);
const SupportCredit = lazy(() =>
  import("./components/dashboard/dashboardSupport/SupportCredit")
);
const SupportStarted = lazy(() =>
  import("./components/dashboard/dashboardSupport/SupportStarted")
);
const PageNotFound = lazy(() => import("./components/myMatch/PageNotFound"));
const MatchProfile = lazy(() => import("./components/myMatch/MatchProfile"));
const SignIn = lazy(() => import("./components/matchLogin/SignIn"));
const ForgotPassword = lazy(() =>
  import("./components/forgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./components/resetPassword/ResetPassword")
);
const AdminSignIn = lazy(() => import("./components/admin/login"));
const PostReview = lazy(() => import("./pages/PostReview"));
const ProfessionalImpersonate = lazy(() =>
  import(
    "./components/dashboard/dashboardImpersonateDetail/ProfessionalImpersonate"
  )
);
const PDFTemplatePage = lazy(() => import("./pages/PDFTemplatePage"));
const PendingMatch = lazy(() => import("./components/myMatch/PendingMatch"));
const SuccessMatch = lazy(() => import("./components/myMatch/SuccessMatch"));
const PublicProfile = lazy(() => import("./components/myMatch/PublicProfile"));
const SupportBilling = lazy(() =>
  import("./components/dashboard/dashboardSupport/SupportBilling")
);
const SignUpFlowCredit = lazy(() =>
  import("./components/signUpFlow/SignupFlowCredit")
);
const SignUpFlowPayment = lazy(() =>
  import("./components/signUpFlow/SignUpFlowCheckout")
);

const LeadFlowMain = lazy(() => import("./components/leadFlow/LeadFlowMain"));

function App() {
  const isMobile =
    window.matchMedia &&
    window.matchMedia("only screen and (max-width: 767px)").matches;
  const isLoading = useSelector((state) => state.globalLoader.isLoading);

  useEffect(() => {
    // setTimeout(() => {
    // }, 2000);
    trackingId && ReactGA.initialize(trackingId);
    trackCustomEvent("Home Page", {});
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_MODE_VAR === "production") {
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);

  return (
    <GlobalContextProvider>
      <div className="App">
        <Router>
          <FacebookPixel />
          <Suspense fallback={<Loader isLoading={true} />}>
            <Routes>
              {AdminRoutes()}
              <Route path="/" element={<HomePage />} />
              <Route
                path="/verified-professional/:professionName/:keyword/:location"
                element={<LandingPage />}
              />
              <Route
                path="/verified-professional/:professionName/:keyword"
                element={<LandingPage />}
              />
              <Route
                path="/verified-professional/:professionName"
                element={<LandingPage />}
              />
              <Route
                path="/:id/patient/questions"
                element={<OnBoardingPage />}
              />
              <Route path="/:id/patient/services" element={<LeadFlowMain />} />
              <Route path="/signin" element={<SignIn />} />
              <Route
                key="login"
                path="/admin-signin"
                element={<AdminSignIn />}
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/pdf-template" element={<PDFTemplatePage />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<DashboardLayout />}>
                  <Route index element={<DashboardMain />} />
                  <Route
                    path="messages/:id?"
                    element={<ProfessionalDashboardMessages />}
                  />
                  <Route
                    exact
                    path="matches"
                    element={<DashboardMatchesMain />}
                  />
                  <Route path="matches/:name" element={<LeadDetails />} />
                  <Route path="match-sample" element={<LeadDetailsSample />} />
                  <Route path="invoices" element={<DashboardInvoice />} />
                  <Route
                    path="education-center"
                    element={<DashboardEducationCenter />}
                  />
                  <Route
                    path="education-center/course"
                    element={<CoursesDetail />}
                  />
                  <Route
                    path="education-center/course/lesson/:num"
                    element={<LessonDetail />}
                  />
                  <Route
                    path="old-chats/:id?"
                    element={<ProfessionalDashboardOldMessages />}
                  />
                  <Route
                    path="settings"
                    element={
                      <SettingsLayout title={"User Profile"}>
                        <UserProfile />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/calendar-integration"
                    element={
                      <SettingsLayout title={"Integrations"}>
                        <CalendarIntegration isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/credit-limit"
                    element={
                      <SettingsLayout title={"Credit limit"}>
                        <CreditLimit isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/region"
                    element={
                      <SettingsLayout title={"Region"}>
                        <Region isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/sms-template"
                    element={
                      <SettingsLayout title={"Sms template"}>
                        <SmsTemplate isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/email-template"
                    element={
                      <SettingsLayout title={"Email template"}>
                        <EmailTemplate isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/billing-payment"
                    element={
                      <SettingsLayout title={"Billing & Invoices"}>
                        <BillingPayment />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/notification-settings"
                    element={
                      <SettingsLayout title={"Notifications"}>
                        <NotificationSettings isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/password-settings"
                    element={
                      <SettingsLayout title={"Change Password"}>
                        <PasswordSettings isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route
                    path="settings/security-settings"
                    element={
                      <SettingsLayout title={"Active Sessions"}>
                        <SecuritySettings isMobile={isMobile} />
                      </SettingsLayout>
                    }
                  />
                  <Route path="support" element={<DashboardSupportMain />} />
                  <Route element={<SupportLayout />}>
                    <Route
                      path="support/contact-us"
                      element={<SupportContactUs />}
                    />
                    <Route
                      exact
                      path="support/credit"
                      element={<SupportCredit />}
                    />
                    <Route
                      path="support/getting-started"
                      element={<SupportStarted />}
                    />
                    <Route
                      path="support/billing"
                      element={<SupportBilling />}
                    />
                  </Route>
                  <Route
                    path="settings/email-template/:title"
                    element={<EmailTemplateEditor />}
                  />
                  <Route
                    path="professional-impersonate"
                    element={<ProfessionalImpersonate />}
                  />
                </Route>
                <Route
                  path="/profile-optimization/:step"
                  element={<ProfileOptimizationPage />}
                />
                <Route
                  path="/profile-optimization/package-plan"
                  element={<SignUpFlowCredit />}
                />
                <Route
                  path="/profile-optimization/package-plan/payment"
                  element={<SignUpFlowPayment />}
                />
              </Route>
              <Route
                path="/signup/step/:flowstep"
                element={<SignUpFlowPage />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/terms-of-use" element={<UseTermsPage />} />
              <Route path="/terms-of-service" element={<ServicesTermsPage />} />
              <Route path="/complete" element={<LastPage />} />
              <Route path="/no-match" element={<PageNotFound />} />
              <Route path="/pending-match" element={<PendingMatch />} />
              <Route path="/success-match" element={<SuccessMatch />} />
              <Route path="/match-profile" element={<MatchProfile />} />
              <Route path="/public-profile" element={<PublicProfile />} />
              <Route path="/post-review" element={<PostReview />} />
              <Route path="*" element={<ErrorPage />} />
              <Route path="/page-not-found" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </Router>
        <ToastContainer />
        <Loader isLoading={isLoading} />
      </div>
    </GlobalContextProvider>
  );
}

export default App;
