// participantSlice.js
import { createSlice } from '@reduxjs/toolkit';

const chatParticipantSlice = createSlice({
  name: 'chatParticipants',
  initialState: {
    filteredParticipants: [],
    remainingParticipants: [],
  },
  reducers: {
    setFilteredChatParticipants: (state, action) => {
      state.filteredParticipants = action.payload.filtered;
      state.remainingParticipants = action.payload.remaining;
    },
  },
});

export const { setFilteredChatParticipants } = chatParticipantSlice.actions;

export default chatParticipantSlice.reducer;
