import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const professionalInfo = createApi({
  reducerPath: "professionalInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: ["professionalInfoTag"],
  endpoints: (builder) => ({
    getProfessionalInfo: builder.query({
      query: () => `/user`,
      providesTags: [{ type: "professionalInfoTag", id: "getUserInfo" }],
    }),
    getProfessionById: builder.query({
      query: (userId) => `/user/${userId}`,
    }),
    getMatchProfessionById: builder.query({
      query: (userId) => `/public/matches/${userId}`,
    }),
    setUserRegion: builder.mutation({
      query: (regionData) => ({
        url: "/user/regions",
        method: "POST",
        body: regionData,
      }),
    }),
    saveProfessionalsServices: builder.mutation({
      query: (services) => ({
        url: "/user/assign-services",
        method: "POST",
        body: services,
      }),
      invalidatesTags: [{ type: "professionalInfoTag", id: "getUserInfo" }],
    }),
    saveProfessionalInfo: builder.mutation({
      query: (info) => ({
        url: "/user",
        method: "POST",
        body: info,
      }),
      invalidatesTags: [{ type: "professionalInfoTag", id: "getUserInfo" }],
    }),
    updateProfessionalInfo: builder.mutation({
      query: (pass) => ({
        url: "/user/update-password",
        method: "POST",
        body: pass,
      }),
    }),
    saveProfessionalOfficePhoto: builder.mutation({
      query: (office_photos) => ({
        url: "/user/office-photos",
        method: "POST",
        body: office_photos,
      }),
    }),
    deleteRegion: builder.mutation({
      query: (regionId) => ({
        url: `/regions/${regionId}`,
        method: "DELETE",
      }),
    }),
    deleteProfilePhoto: builder.mutation({
      query: () => ({
        url: "/user/photo",
        method: "DELETE",
      }),
    }),
    changePassword: builder.mutation({
      query: (password) => ({
        url: "/user/update-old-password",
        method: "POST",
        body: password,
      }),
    }),
    searchLeads: builder.query({
      query: (arg) => {
        return { url: `/search/leads`, params: { ...arg } };
      },
    }),
    saveContactUsDetails: builder.mutation({
      query: (contact) => ({
        url: "/contact-messages",
        method: "POST",
        body: contact,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetProfessionalInfoQuery,
  useLazyGetProfessionalInfoQuery,
  useSetUserRegionMutation,
  useGetProfessionByIdQuery,
  useGetMatchProfessionByIdQuery,
  useDeleteRegionMutation,
  useSaveProfessionalsServicesMutation,
  useSaveProfessionalInfoMutation,
  useSaveProfessionalOfficePhotoMutation,
  useDeleteProfilePhotoMutation,
  useUpdateProfessionalInfoMutation,
  useChangePasswordMutation,
  useSearchLeadsQuery,
  useLazySearchLeadsQuery,
  useSaveContactUsDetailsMutation,
} = professionalInfo;
