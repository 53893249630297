import { useDispatch } from "react-redux";
import { setLoader } from "../store/reducers/globalLoaderSlice";

export const unAuthenticatedUser = async (url, options, extra) => {
  const response = await fetch(url, options);
  if (response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("smsTemplate");
    window.location.replace("/signin");
  }
  return response;
};

export const unAuthenticatedAdmin = async (url, options, extra) => {
  try {
    const response = await fetch(url, options);
  if (response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("smsTemplate");
    window.location.replace("/admin-signin");
  }
  return response;
  } catch (error) {
    console.log(error,"error")
  }
  
};
