import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getLeadDetails = createApi({
  reducerPath: "leadDetails",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
    responseHandler: async (response) => {
      if (response.headers.get("Content-Type")?.includes("text/csv")) {
        // Handle CSV response
        return response.text();
      } else {
        // Handle JSON response
        return response.json();
      }
    },
  }),
  tagTypes: ['getLeadDetails'],
  endpoints: (builder) => ({
    getLeadDetails: builder.query({
      query: () => `/user-metrics`,
    }),
    getLeadData: builder.query({
      query: (id) => `/leads/${id}`,
      providesTags: [{ type: "getLeadDetails", id: "getLeadData" }],
    }),
    getLeadCsv: builder.query({
      query: (id) => `/leads/${id}/export`,
    }),
    saveLeadNote: builder.mutation({
      query: (note) => ({
        url: `matches/${note.id}/notes`,
        method: "POST",
        body: note,
      }),
      invalidatesTags: [{ type: "getLeadDetails", id: "getLeadData" }],
    }),
  }),
});

export const saveLeadServices = createApi({
  reducerPath: "leadServices",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    saveLeadServices: builder.mutation({
      query: (services) => ({
        url: "/leads/services",
        method: "POST",
        body: services,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetLeadDetailsQuery,
  useGetLeadDataQuery,
  useLazyGetLeadDataQuery,
  useLazyGetLeadCsvQuery,
  useSaveLeadNoteMutation
} = getLeadDetails;
export const { useSaveLeadServicesMutation } = saveLeadServices;
