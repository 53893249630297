import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const templateCreation = createApi({
  reducerPath: "templatecreation",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: () => `/message-templates`,
    }),
    saveTemplates: builder.mutation({
      query: (template) => ({
        url: `/message-templates`,
        method: "POST",
        body: template,
      }),
    }),
    updateTemplates: builder.mutation({
      query: (template) => ({
        url: `/message-templates/${template.id}`,
        method: "POST",
        body: template,
      }),
    }),
    deleteTemplates: builder.mutation({
      query: (templateId) => ({
        url: `/message-templates/${templateId}`,
        method: "DELETE",
      }),
    }),
    followUpNotify: builder.mutation({
      query: (body) => ({
        url: `/followup-notification`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "matchesType", id: "get-matches" }],
    }),
  }),
});
//api/v2/followup-notification
// Export hooks for usage in functional components
export const {
  useFollowUpNotifyMutation,
  useGetTemplatesQuery,
  useLazyGetTemplatesQuery,
  useUpdateTemplatesMutation,
  useSaveTemplatesMutation,
  useDeleteTemplatesMutation,
} = templateCreation;
