import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const adminProfessions = createApi({
  reducerPath: "adminProfessions",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/admin/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin
  }),
  tagTypes: ["AdminProfessions"],
  endpoints: (builder) => ({
    getAllProfessions: builder.query({ query: () => "/professions" }),
  }),
});

export const { useGetAllProfessionsQuery } = adminProfessions;
