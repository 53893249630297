import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { useLocation } from "react-router-dom";

const fbPixelId = process.env.REACT_APP_FB_PIXEL_ID;

const FacebookPixel = () => {
  const location = useLocation();
  useEffect(() => {
    const advancedMatching = {}; // Add any custom user parameters here
    const options = {
      autoConfig: true, // Set to false if you don't want to send the config event automatically
      debug: false, // Set to true if you want to enable logging
    };

    ReactPixel.init(fbPixelId, advancedMatching, options);
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  return null; // This component doesn't render anything
};

export const trackCustomEvent = (label, params = {}) => {
  console.log(label, "label params", params);
  ReactPixel.track(label, {
    ...params,
  });
};

export const trackLandingPageVisit = (label, params = {}) => {
  const eventLabel = `LP ${label} Visit`;
  console.log(eventLabel, "label params", params);
  ReactPixel.track(eventLabel, {
    ...params,
  });
};

export default FacebookPixel;
