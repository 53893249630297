import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProfessionsData: [],
};

const professionsSlice = createSlice({
  name: "professionsSlice",
  initialState,
  reducers: {
    saveProfession: (state, action) => {
      state.selectedProfessionsData = [...action.payload];
    },
  },
});

export const { saveProfession } = professionsSlice.actions;
export const selectedProfessions = (state) =>
  state.professionsSlice.selectedProfessionsData;
export default professionsSlice.reducer;
