import { createContext, useContext, useState } from "react";

const ProgressContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [progressBar, setProgressBar] = useState("20")

  return (
    <ProgressContext.Provider value={{ progressBar, setProgressBar }}>
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgressBarContext = () => {
  const context = useContext(ProgressContext);
  if (!context) {
    throw new Error("useProgressBar must be used within a ProgressBarContextProvider");
  }
  return context;
};
