import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const getTestimonials = createApi({
  reducerPath: "testimonials",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getTestimonials: builder.query({
      query: () => `/testimonials`,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetTestimonialsQuery } = getTestimonials;
