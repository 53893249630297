import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const impersonateProfessional = createApi({
  reducerPath: "impersonateProfessional",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin
  }),
  tagTypes: ["ImpersonateProfessional"],
  endpoints: (builder) => ({
    getImpersonateProfessional: builder.query({
      query: () => {
        return {
          url: "/user",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetImpersonateProfessionalQuery } = impersonateProfessional;
